<template>
  <div>
    <Panel header="Cancelar/Suspender Reserva Cartão" class="mt-3">
      <div class="formgrid grid">
        <div class="field col-6 md:col-6">
          <Button
            label="Voltar"
            icon="pi pi-chevron-left"
            class="mt-2 mr-2 p-button-sm p-button-secondary"
            @click="$router.back()" />
        </div>
      </div>
      <form @submit.prevent="validate()">
        <div class="p-fluid formgrid grid">
          <div class="field col-4 md:col-4">
            <label for="tipo" class="required">Tipo</label>
            <Dropdown
              id="tipo"
              v-model="v$.tipo.$model"
              :options="tipos"
              optionLabel="nome"
              required
              :class="{ 'p-invalid': submitted && v$.tipo.$invalid }"
              placeholder="Selecione um tipo"
              @change="textoObservacao()">
            </Dropdown>
            <div v-if="submitted && v$.tipo.required.$invalid" class="p-error">
              O campo Tipo é obrigatório
            </div>
          </div>
          <div class="field col-4 md:col-4">
            <label for="motivo" class="required">Judicial</label>
            <Dropdown
              id="motivo"
              v-model="v$.motivo.$model"
              :options="[
                { nome: 'Sim', code: 'JUDICIAL' },
                { nome: 'Não', code: 'NENHUM' },
              ]"
              optionLabel="nome"
              placeholder="Selecione se é judicial ou não"
              required
              :class="{ 'p-invalid': submitted && v$.motivo.$invalid }"
              @change="changeMotivo()">
            </Dropdown>
            <div
              v-if="submitted && v$.motivo.required.$invalid"
              class="p-error">
              O campo Motivo é obrigatório
            </div>
          </div>
          <div v-if="isJudicial" class="field col-4 md:col-4">
            <label for="DataDocumento" class="required"
              >Data do documento</label
            >
            <Calendar
              id="DataDocumento"
              v-model="v$.dataDocumento.$model"
              :class="{
                'p-invalid':
                  submitted &&
                  (v$.dataDocumento.$invalid ||
                    dataDocumentoInvalida ||
                    dataDocumentoPosteriorDataAtual),
              }"
              :showIcon="true"
              dateFormat="dd/mm/yy" />
            <div
              v-if="submitted && v$.dataDocumento.required.$invalid"
              class="p-error">
              O campo Data do Documento é obrigatório
            </div>
            <div v-if="dataDocumentoInvalida" class="p-error">
              O campo Data do Documento está incorreto.
            </div>
            <div v-if="dataDocumentoPosteriorDataAtual" class="p-error">
              A Data do Documento está posterior a data de hoje.
            </div>
          </div>
        </div>
        <div v-if="isJudicial" class="p-fluid formgrid grid">
          <div class="field col-3 md:col-3">
            <label for="numeroOficio" class="required">Número do ofício</label>
            <InputText
              id="numeroOficio"
              v-model="v$.historicoConsignacao.numeroOficio.$model"
              :class="{
                'p-invalid':
                  submitted && v$.historicoConsignacao.numeroOficio.$invalid,
              }" />

            <div
              v-if="
                submitted &&
                v$.historicoConsignacao.numeroOficio.required.$invalid
              "
              class="p-error">
              O campo Número de Ofício é obrigatório
            </div>
          </div>

          <div class="field col-3 md:col-3">
            <label for="numeroProcesso" class="required"
              >Número do processo</label
            >
            <InputText
              id="numeroProcesso"
              v-model="v$.historicoConsignacao.numeroProcesso.$model"
              :class="{
                'p-invalid':
                  submitted && v$.historicoConsignacao.numeroProcesso.$invalid,
              }"
              @change="textoObservacao()" />
            <div
              v-if="
                submitted &&
                v$.historicoConsignacao.numeroProcesso.required.$invalid
              "
              class="p-error">
              O campo Número de Processo é obrigatório
            </div>
          </div>

          <div class="field col-3 md:col-3">
            <label for="juizado" class="required">Juizado</label>
            <InputText
              id="juizado"
              v-model="v$.historicoConsignacao.juizado.$model"
              :class="{
                'p-invalid':
                  submitted && v$.historicoConsignacao.juizado.$invalid,
              }" />
            <div
              v-if="
                submitted && v$.historicoConsignacao.juizado.required.$invalid
              "
              class="p-error">
              O campo Juizado é obrigatório
            </div>
          </div>

          <div class="field col-3 md:col-3">
            <label for="nomeJuiz" class="required">Nome do Juiz</label>
            <InputText
              id="nomeJuiz"
              v-model="v$.historicoConsignacao.nomeJuiz.$model"
              :class="{
                'p-invalid':
                  submitted && v$.historicoConsignacao.nomeJuiz.$invalid,
              }" />
            <div
              v-if="
                submitted && v$.historicoConsignacao.nomeJuiz.required.$invalid
              "
              class="p-error">
              O campo Nome do Juiz é obrigatório
            </div>
          </div>
        </div>

        <div class="p-fluid formgrid grid col-12">
          <div class="field md:col-12">
            <label for="observacao" class="required">Observação</label>
            <Textarea
              id="observacao"
              v-model="v$.historicoConsignacao.observacao.$model"
              :class="{
                'p-invalid':
                  submitted && v$.historicoConsignacao.observacao.$invalid,
              }"
              :autoResize="true" />
            <div
              v-if="
                submitted &&
                v$.historicoConsignacao.observacao.required.$invalid
              "
              class="p-error">
              O campo Observação é obrigatório
            </div>
          </div>
        </div>

        <Button
          type="submit"
          label="Salvar"
          icon="pi pi-save"
          class="md:col-2"></Button>
      </form>
    </Panel>
    <modal-senha-servidor
      :exibir="exibirModalSenhaServidor"
      :metodoSalvar="salvar"
      :tipo="false"
      @mudarVisibilidade="exibirModalSenhaServidor = $event">
    </modal-senha-servidor>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import moment from 'moment'
import Consignacao from '@/domain/Consignacao.js'
import HistoricoConsignacao from '@/domain/HistoricoConsignacao.js'
import HistoricoConsignacaoService from '@/service/HistoricoConsignacaoService'
import ConsignacaoService from '@/service/ConsignacaoService'
import UtilService from '@/service/UtilService'
import { servidorStore } from '@/stores/servidor'
import ModalSenhaServidor from '@/components/shared/modal/modalSenhaServidor.vue'
import ReservaCartaoService from '@/service/ReservaCartaoService.js'

export default {
  components: {
    'modal-senha-servidor': ModalSenhaServidor,
  },

  setup() {
    const store = servidorStore()
    return { store, v$: UseVuelidate() }
  },

  data() {
    return {
      consignacao: new Consignacao(),
      historicoConsignacao: new HistoricoConsignacao(),
      submitted: false,
      submitStatus: null,
      tipo: null,
      motivo: '',
      dataDocumento: null,
      dataDocumentoInvalida: false,
      dataDocumentoPosteriorDataAtual: false,
      dataAtual: null,
      situacao: null,
      cadastradoEm: null,
      processadoEm: null,
      isJudicial: false,
      idCartao: this.$route.params.idCartao,
      reservaCartao: null,
      tipos: [
        { nome: 'Cancelamento', code: 'CANCELADA' },
        { nome: 'Suspensão', code: 'SUSPENSA' },
      ],
      exibirModalSenhaServidor: false,
    }
  },

  created() {
    this.service = new ConsignacaoService(this.$http)
    this.reservaCartaoService = new ReservaCartaoService(this.$http)
    this.utilService = new UtilService(this.$http)
    this.historicoConsignacaoService = new HistoricoConsignacaoService(
      this.$http,
    )
    this.reservaCartaoService
      .getReservaCartaoById(this.idCartao)
      .then((res) => {
        this.reservaCartao = res
        this.cadastradoEm = this.formatarData(this.reservaCartao.cadastradoEm)
        this.processadoEm = this.formatarData(this.reservaCartao.processadoEm)
      })
      .catch(() => {
        this.$toast.add({
          severity: 'error',
          summary: 'Ocorreu um erro. Tente novamente!',
          life: 10000,
        })
      })
  },

  mounted() {
    this.carregaDataAtual()
  },

  validations() {
    return {
      historicoConsignacao: {
        numeroOficio: { required },
        numeroProcesso: { required },
        nomeJuiz: { required },
        juizado: { required },
        observacao: { required },
      },
      tipo: { required },
      motivo: { required },
      dataDocumento: { required },
    }
  },

  methods: {
    validate() {
      this.submitted = true
      this.v$.historicoConsignacao.$touch()

      if (this.validaDataDocumento()) return
      if (this.isDataDocumentoEPosteriorDataAtual()) return

      if (this.motivo.code == 'JUDICIAL') {
        if (
          this.v$.historicoConsignacao.nomeJuiz.$invalid ||
          this.v$.historicoConsignacao.juizado.$invalid ||
          this.v$.historicoConsignacao.numeroProcesso.$invalid ||
          this.v$.historicoConsignacao.numeroOficio.$invalid ||
          this.v$.historicoConsignacao.observacao.$invalid ||
          this.v$.tipo.$invalid ||
          this.v$.motivo.$invalid ||
          this.v$.dataDocumento.$invalid
        ) {
          return
        } else {
          this.exibirModalSenhaServidor = true
        }
      } else {
        if (
          this.v$.historicoConsignacao.observacao.$invalid ||
          this.v$.tipo.$invalid ||
          this.v$.motivo.$invalid ||
          this.v$.dataDocumento.$invalid
        ) {
          return
        } else {
          this.exibirModalSenhaServidor = true
        }
      }
    },

    validaDataDocumento() {
      if (!moment(this.dataDocumento, 'DD/MM/YYYY').isValid()) {
        this.dataDocumentoInvalida = true
        return true
      } else {
        this.dataDocumentoInvalida = false
        return false
      }
    },

    cancelar() {
      this.loading = true
      this.reservaCartaoService
        .cancelarReservarCartao(this.idCartao)
        .then(() => {
          this.historicoConsignacaoService
            .saveHistoricoConsignacao(this.historicoConsignacao)
            .then(() => {
              this.$toast.add({
                severity: 'success',
                summary: 'Reserva de Cartão cancelada com sucesso!',
                life: 10000,
              })
              this.loading = false
              this.$router.push({ name: 'reserva_cartao' })
            })
            .catch((err) => {
              console.log(err)
              this.loading = false
              this.$toast.add({
                severity: 'error',
                summary: err.response.data.message,
                life: 10000,
              })
            })
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    suspender() {
      this.reservaCartaoService
        .suspenderReservarCartao(this.idCartao, this.historicoConsignacao)
        .then(() => {
          this.loading = false
          this.$toast.add({
            severity: 'success',
            summary: 'Reserva de Cartão suspensa com sucesso!',
            life: 10000,
          })
          this.$router.push({ name: 'reserva_cartao' })
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },

    isDataDocumentoEPosteriorDataAtual() {
      if (
        moment(this.dataDocumento)
          .startOf('day')
          .isAfter(moment(this.dataAtual).startOf('day'))
      ) {
        this.dataDocumentoPosteriorDataAtual = true
        return true
      } else {
        this.dataDocumentoPosteriorDataAtual = false
        return false
      }
    },

    salvar() {
      this.atribuirDados()
      if (this.tipo.code == 'CANCELADA') {
        this.cancelar()
      } else if (this.tipo.code == 'SUSPENSA') {
        this.suspender()
      }
    },

    atribuirDados() {
      this.historicoConsignacao.alteradoPor = this.alteradoPor
      this.historicoConsignacao.cadastradoPor = this.cadastradoPor
      this.historicoConsignacao.consignacao = this.consignacao
      this.historicoConsignacao.tipo = this.tipo.code
      this.historicoConsignacao.motivo = this.motivo.code
      if (this.dataDocumento !== null) {
        this.historicoConsignacao.dataDocumento = this.formatarDataParaSalvar(
          this.dataDocumento,
        )
      }
      this.historicoConsignacao.reservaCartao = this.reservaCartao
      this.historicoConsignacao.consignacao = null
    },

    carregaDataAtual() {
      this.utilService.devolveMesAnoAtual().then(
        (res) => {
          this.dataAtual = res.dia + '/' + res.mes + '/' + res.ano
          //this.dataAtual = moment(data, 'DD/MM/YYYY')
          //moment(data).toDate()
          this.dataDocumento = res.dia + '/' + res.mes + '/' + res.ano
        },
        (err) => {
          if (err) {
            this.exibeToast('error-data')
          }
        },
      )
    },

    limparCampos() {
      this.submitted = false
      this.v$.$reset()
      this.consignacao = new Consignacao()
    },

    exibeToast(tipo) {
      if (tipo === 'success') {
        this.$toast.add({
          severity: 'success',
          summary: 'Cancelado/Suspensa com sucesso!',
          life: 10000,
        })
      } else if (tipo === 'error') {
        this.$toast.add({
          severity: 'error',
          summary:
            'Erro ao tentar cancelar/suspender. Entre em contato com o suporte.',
          life: 10000,
        })
      } else if (tipo === 'error-data') {
        this.$toast.add({
          severity: 'error',
          summary: 'Erro ao tentar trazer a data atual.',
          life: 10000,
        })
      }
    },

    changeMotivo() {
      if (this.motivo.code == 'JUDICIAL') {
        this.isJudicial = true
      } else {
        this.isJudicial = false
      }
      this.textoObservacao()
    },

    textoObservacao() {
      if (this.motivo.code == 'JUDICIAL') {
        this.historicoConsignacao.observacao =
          'Reserva de cartão ' +
          (this.tipo !== null ? this.tipo.code : '') +
          ' por ordem judicial nº ' +
          (this.historicoConsignacao.numeroProcesso !== null
            ? this.historicoConsignacao.numeroProcesso
            : '') +
          ', na data ' +
          this.dataDocumento
        //"Colocar na observação o texto default “Reserva de cartão (Tipo de"
        //Operação 1 = Cancelada) por ordem judicial nº (numero_processo), na data (data_cadastro)"
      } else {
        //Operção de Cancelamento - Judicial (false) Colocar na observação o texto default “Reserva de cartão (Tipo de
        //Operação 1 = Cancelada) na data (data_cadastro)"
        this.historicoConsignacao.observacao =
          'Reserva de cartão ' +
          (this.tipo !== null ? this.tipo.code : '') +
          ' na data ' +
          this.dataDocumento
      }
    },

    formatarData(value) {
      if (!value) return ''
      return new Date(value).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    },

    formatarDataParaSalvar(value) {
      let data_americana = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
      return data_americana
    },

    moment: function () {
      return moment()
    },
  },
}
</script>

<style scoped>
.required:after {
  content: ' *';
  color: red;
}
</style>
